import React from 'react';
import {Link} from 'react-router-dom';
import { AttentionWrapper, BlackWeekIcon, CompetitorButton, Container, Content, CrossContainer, CrossIcon, Curtain, Esgotado, EsgotadoText, Footer, FreeButton, Icon, PercentIcon, Plan, Plans, PremiumButton, Text, Top } from './style';
import {freePlan, featuredPlan, competitorPlan} from '../../Utils/Constants';
import WantToBeOoppah from '../../assets/quero_ser_ooppah.svg';
import Warning from '../../assets/atencao.svg';
import Item from './Item';
import Cross from '../../assets/logoUnCheck.png';
import Percent from '../../assets/70percent.png';
import BlackWeek from '../../assets/blackweeklogo.png'
import Blackooppah from '../../assets/blackooppah.png'


export default function PackagesPremium (props) {

    return(
        <Container>
            <a name="packages" />
            <Top>
                <Text size={"1.5em"}>
                    <b>Escolha seu melhor plano</b>
                    
                </Text>
            </Top>

            <Plans>
                <Plan>
                    <div>
                        <Top>
                            <Text size={"1.1em"}>
                                <b>Perfil Gratuito</b>
                            </Text>
                            <Text size={"0.7em"} color={"#357500"}>
                                Ganhe <b>15 dias</b> de acesso Premium
                            </Text>
                        </Top>
                        <Content>
                            {
                                freePlan.map((item, index) => {
                                    return (
                                    <Item key = {index} item = {item} />
                                    );
                                })
                            }
                        </Content>
                    </div>
                    <Footer>
                        <FreeButton><Link style={{color: 'white', width:'100%', display:'inline-block'}} to={'/plans'} >Grátis</Link></FreeButton>
                    </Footer>
                </Plan>


                <Plan>
                    <Top>
                        <Text size={"1.1em"}>
                            <b>Perfis Assinantes</b>
                        </Text>
                    </Top>
                    <Content>
                        {
                            featuredPlan.map((item, index) => {
                                return (
                                   <Item key = {index} item = {item} />
                                );
                            })
                        }
                    </Content>
                    <Footer>
                        {/*<BlackWeekIcon src={Blackooppah} />*/}
                        <Text>A partir de R$ <span style={{fontSize: 23, fontWeight: 'bold'}}>24,00</span>/mês</Text>
                        <Text>ou </Text>
                        {/** <Text><b>até <CrossContainer><PercentIcon src = {Percent} /><CrossIcon src={Cross} /> 50%</CrossContainer> de Desconto no Plano Anual</b></Text>*/}
                        <Text><b>até 50% de Desconto no Plano Anual</b></Text>
                        <PremiumButton><Link style={{color: 'white', width:'100%', display:'inline-block'}} to={'/plans'} >Sim! Eu mereço</Link></PremiumButton>
                    </Footer>
                    {/* 
                    <Esgotado>
                        ESGOTADO
                    </Esgotado>
                    <EsgotadoText>
                        Aguarde novas vagas ou inscreva-se no Plano Gratuito
                        <a href="https://forms.gle/4Qk1zHqGg2cdiijEA"> Entre na lista de espera clicando aqui</a>
                    </EsgotadoText>
                    <Curtain></Curtain>
                    */}
                </Plan>


                <Plan>
                    <div>
                    <Top>
                        <Text size={"1.1em"}>
                            <b>Outras plataformas</b>
                        </Text>
                    </Top>
                    <Content>
                        {
                            competitorPlan.map((item, index) => {
                                return (
                                   <Item key = {index} item = {item} />
                                );
                            })
                        }
                    </Content>
                    </div>
                    <Footer>
                        <Text>Ooppah <b>não tem comparação</b></Text>
                        <CompetitorButton>
                            <Link style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', width:'100%'}} to={'/plans'} >Quero ser Ooppah <Icon src = {WantToBeOoppah} /></Link> 
                        </CompetitorButton>
                    </Footer>
                </Plan>


            </Plans>

            <Footer>
                <AttentionWrapper>
                    <Icon src = {Warning}/>
                    <Text size={"1.0em"}>
                        &nbsp; ATENÇÃO: O Ooppah <b>não é uma agência</b> e <b>não cobra repasses</b> por trabalhos fechados.
                    </Text>
                </AttentionWrapper>
                <Text size={"0.8em"}>
                    A assinatura do perfil premium não garante seleção, testes e/ou escalação em trabalhos.
                </Text>
            </Footer>
        </Container>

    );

}